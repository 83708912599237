import React from 'react'
import Layout from '../components/layout'
import HTML from '../components/HTML'
import Offers from '../components/currentOffers/Offers'
import RichText from '../components/currentOffers/richText/RichText'

function CurrentOffers(props) {
    const { pageContext } = props
    const { pageContent, dealerInfo } = pageContext

    let components = [
        <RichText key={"RichText"} data={pageContent.RichText} seoText={pageContent.RichText.seoText}/>,
        <Offers key={"Offers"} quickgenId={pageContent.Offers.quickgenId} liveActionShots={pageContent.Offers.liveActionShots} data={pageContent.Offers} dealerInfo={dealerInfo} />,
    ]

    //Map Raw HTML components
    if(pageContent.hasOwnProperty("ArrayRawHTML")) {
        const { arrayRawHTML } = pageContent.ArrayRawHTML
        const rawComponents = arrayRawHTML.map(element => {  
            return <HTML data={{ 
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
    }
    
    //Sort components by order
    let sortedComponents = components.sort((a, b) => parseFloat(a.props.data.order) - parseFloat(b.props.data.order))
    
    //Filter out invisible components
    let visibleComponents = sortedComponents.filter(component => {
        return component.props.data.visible === true
    })

    return (
        <Layout seo={pageContent.SEO}>
            { visibleComponents }
            <data id="pageType" value="OEM Incentives"></data>
        </Layout>
    )
}

export default CurrentOffers